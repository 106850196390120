import React from 'react';
import './App.css';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUsers, faChartBar, faHandshake, faChartPie, faRobot } from '@fortawesome/free-solid-svg-icons';
import logo from './img/logo.png'; // Import the logo image

interface Service {
  title: string;
  description: string;
  icon: any;
}

const services: Service[] = [
  { title: 'Comprehensive Sales Strategy', description: 'Create a strategy aligned with your business objectives and target market.', icon: faChartLine },
  { title: 'Sales Team Training', description: 'Develop and implement programs to enhance your team\'s skills and performance.', icon: faUsers },
  { title: 'Performance Tracking & Analysis', description: 'Establish KPIs and implement systems to track and improve sales performance.', icon: faChartBar },
  { title: 'Lead Generation & Management', description: 'Outsource lead generation and management to ensure a steady pipeline of potential clients.', icon: faHandshake },
  { title: 'Sales Forecasting', description: 'Develop accurate forecasts to help your business make informed decisions.', icon: faChartPie },
  { title: 'AI-Powered Sales Optimization', description: 'Leverage cutting-edge AI technologies to automate and enhance your sales processes.', icon: faRobot },
];

const Header: React.FC = () => (
  <div className="header">
    <img src={logo} alt="Logo" className="header-logo" />
    <h1>Welcome</h1>
     <p>Ready to start your sales acceleration experience?</p>
  </div>
);

const Services: React.FC = () => (
  <div className="services">
    {services.map((service, index) => (
      <div key={index} className="service-item">
        <FontAwesomeIcon icon={service.icon} />
        <h3>{service.title}</h3>
        <p>{service.description}</p>
      </div>
    ))}
  </div>
);

const ContactForm: React.FC = () => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    emailjs.sendForm('service_d634vwq', 'template_9hdmcaq', e.currentTarget, 'RV5BN9d-2Q94aQLvR')
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message!');
      });
  };

  return (
    <div className="contact">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <input type="text" name="from_name" placeholder="Your Name" required />
        <input type="email" name="from_email" placeholder="Your Email" required />
        <textarea name="message" placeholder="Your needs and questions" required />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <div className="parallax-container">
      <div className="parallax-section">
        <div className="parallax-bg"></div>
        <Header />
        <div className="mac-window">
          <div className="window-header">
            <div className="window-buttons">
              <div className="window-button close"></div>
              <div className="window-button minimize"></div>
              <div className="window-button maximize"></div>
            </div>
            <div className="window-title">ZenSales - Your Sales Excellence Partner</div>
          </div>
          <div className="window-content">
            <h2>Our Services</h2>
            <p>We offer our services in Europe.</p>
            <Services />
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;